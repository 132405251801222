import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Wrapper, Button, H, Image, ArrowRightIcon } from '@farewill/ui'
import { BORDER, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'

import WrapperWithEdge from 'components/WrapperWithEdge'
import ExternalLink from 'components/ExternalLink'

const StyledWrapperWithEdge = styled(WrapperWithEdge)`
  overflow: hidden;
  padding: ${GTR.L} 0 0;

  ${screenMax.s`
    > *:first-child {
      display: none;
    }
    > *:last-child {
      padding: 0;
      background: transparent;
    }
  `}
`

const StyledTextureOne = styled(Image)`
  position: absolute;
  pointer-events: none;
  left: -${GTR.M};
  bottom: calc(-2 * ${GTR.L});
  max-height: calc(100% + 3 * ${GTR.L});

  ${screenMax.s`
    display: none;
  `}
`

const StyledTextureTwo = styled(Image)`
  position: absolute;
  pointer-events: none;
  right: 5%;
  top: 5%;

  ${screenMax.s`
    display: none;
  `}
`

const StyledWrapper = styled(Wrapper)`
  position: relative;
  background: ${COLOR.WHITE};
  ${screenMin.m`
    border-radius: ${BORDER.RADIUS.M};
    box-shadow: ${BORDER.SHADOW.M};
  `}
`

const StyledDepartmentList = styled.ul`
  border-top: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledDepartmentListItem = styled.li`
  padding: ${GTR.S} 0;
  border-bottom: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledArrowIcon = styled(ArrowRightIcon)`
  right: 0;
  transition: transform 0.15s ease-in-out;
`

const StyledButton = styled(Button.Plain)`
  color: ${COLOR.BLACK};
  &:hover {
    text-decoration: underline;
  }

  &:hover ${StyledArrowIcon} {
    transform: translate(-${GTR.XXS}, 0);
  }
`

const Jobs = ({
  jobsByDepartment,
}: {
  jobsByDepartment: Record<string, { url: string; title: string }[]>
}) => (
  <>
    {Object.entries(jobsByDepartment).map(([department, departmentJobs]) => (
      <section key={department}>
        <H
          size="M"
          tag="h3"
          margin={['L', 0, 'S']}
          marginFromM={['XL', 0, 'S']}
        >
          {department}
        </H>
        <StyledDepartmentList>
          {departmentJobs.map((job) => (
            <StyledDepartmentListItem key={job.url}>
              <StyledButton
                tag={ExternalLink}
                href={job.url}
                stretch
                separateIcon
                icon={<StyledArrowIcon size="S" />}
                textAlignOnWrap="left"
                flush
              >
                {job.title}
              </StyledButton>
            </StyledDepartmentListItem>
          ))}
        </StyledDepartmentList>
      </section>
    ))}
  </>
)

const JobsList = ({
  anchorElementId,
}: {
  anchorElementId: string
}): ReactElement => (
  <StyledWrapperWithEdge
    padding={['L', 'M']}
    direction="up"
    edgeBackgroundColor={COLOR.WHITE}
    data-percy-hide
  >
    <StyledTextureOne path="textures/pattern-2-rotated" width={800} />
    <StyledTextureTwo path="textures/pattern-1" width={500} />

    <StyledWrapper
      maxWidth={700}
      margin={['L', 'auto']}
      padding={[0, 'L']}
      paddingFromM="XL"
      id={anchorElementId}
    >
      <h2>Find your place on the team</h2>
      <p>
        All current jobs (except where mentioned) can be worked either remotely
        or at our offices in{' '}
        <ExternalLink href="https://g.page/farewill?share">
          Haggerston, London
        </ExternalLink>
        . If you’ve got any questions before applying, you can email us at{' '}
        <ExternalLink href="mailto:hiring@farewill.com">
          hiring@farewill.com.
        </ExternalLink>
      </p>
      <Jobs
        jobsByDepartment={{
          Wills: [{ title: 'Will Writer', url: '/careers/will-writer' }],
          Partnerships: [
            { title: 'Account Manager', url: '/careers/account-manager' },
          ],
          'Customer Experience': [
            {
              title: 'Customer Experience Specialist',
              url: '/careers/customer-experience-specialist',
            },
          ],
          Legal: [
            {
              title: 'Legal Counsel, Wills & Probate',
              url: '/careers/legal-counsel',
            },
          ],
        }}
      />
    </StyledWrapper>
  </StyledWrapperWithEdge>
)

export default JobsList
